<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="12">
              <a-form-item label="登记人电话">
                <a-input placeholder="请输入登记人电话" :maxLength="11" v-model="queryParam.telephone" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="12">
              <a-form-item label="登记人姓名">
                <a-input placeholder="请输入登记人姓名" v-model="queryParam.visitorName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="12">
              <a-form-item label="出发地区">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="defaultRegion"
                  @change="changeRegion"
                />
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="12">
              <a-form-item label="返回日期">
                <a-date-picker
                  v-model="queryParam.backDate"
                  valueFormat="YYYY-MM-DD"
                />
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="12">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="status" slot-scope="text, record">
          <a-tag color="#f50" v-if="record.status === 0">待审核</a-tag>
          <a-tag color="#87d068" v-else-if="record.status === 1">通过</a-tag>
          <a-tag color="#595959" v-else>不通过</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record,0)" v-if="record.isUpdate === 1">编辑</a>
          <a @click="handleEdit(record,0)" v-else>查看</a>
          <a-divider type="vertical" />
          <a @click="gotoDealInfo(record)">审核</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record,1)">到达登记</a>
        </span>
      </s-table>
      <a-modal
        title="返乡预登记"
        :width="800"
        :destroyOnClose="true"
        v-model="visible"
        :confirmLoading="loading"
        @ok="saveBackInfoApi"
      >
        <a-spin :spinning="loading">
          <a-form :form="form">
            <a-form-item
              style="margin-bottom: 0px;"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="出发地址"
            >
              <a-row :gutter="36">
                <a-col :md="8" :sm="16">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :validateStatus="checkParams.countyFromStatus"
                    :help="checkParams.countyFromStatusMsg"
                  >
                    <a-cascader
                      :allowClear="true"
                      style="width:180px;"
                      :options="regionList"
                      placeholder="省-市-区(县)"
                      :field-names="{label: 'label', value: 'value', children: 'children' }"
                      @change="changeFromRegion"
                      v-decorator="['countyFrom', {initialValue: fromDefaultRegion, rules: [{required: true}]}]"
                      :disabled="mdl.isUpdate <= 0"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="26" :sm="16">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :validateStatus="checkParams.placeFromStatus"
                    :help="checkParams.placeFromStatusMsg"
                  >
                    <a-input
                      placeholder="请输入具体地址"
                      style="width:322px;"
                      v-decorator="['placeFrom', {initialValue: mdl.placeFrom, rules: [{required: true, validator: verifyPlaceFrom, validateTrigger: 'change'}]}]"
                      :disabled="mdl.isUpdate <= 0"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="登记人姓名"
              :required="true"
              style="margin-bottom: 0px;"
            >
              <a-row :gutter="24">
                <a-col :md="12" :sm="16">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :validateStatus="checkParams.visitorNameStatus"
                    :help="checkParams.visitorNameStatusMsg"
                  >
                    <a-input
                      placeholder="请输入登记人姓名"
                      v-model="mdl.visitorName"
                      @change="verifyVisitorName"
                      style="width:206px;"
                      :disabled="mdl.isUpdate <= 0"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="16">
                  <a-form-item
                    :labelCol="{ xs: { span: 24 }, sm: { span: 8 }}"
                    :wrapperCol="wrapperCol"
                    label="类型"
                    :validateStatus="checkParams.visitorTypeStatus"
                    :help="checkParams.visitorTypeStatusMsg"
                  >
                    <a-select
                      v-model="mdl.visitorType"
                      placeholder="请选择类型"
                      @change="changeType"
                      :disabled="mdl.isUpdate <= 0"
                    >
                      <a-select-option :key="0" :value="0">请选择类型</a-select-option>
                      <a-select-option v-for="item in typeList" :key="item.itemId" :value="item.itemId">
                        {{ item.itemName }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <!--a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="登记人姓名"
              :validateStatus="checkParams.visitorNameStatus"
              :help="checkParams.visitorNameStatusMsg"
            >
              <a-input
                placeholder="请输入登记人姓名"
                v-decorator="['visitorName', {initialValue: mdl.visitorName, rules: [{required: true, validator: verifyVisitorName, validateTrigger: 'change'}]}]"
                :disabled="mdl.isUpdate <= 0"
              />
            </a-form-item-->
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="登记人手机号"
              :validateStatus="checkParams.telephoneStatus"
              :help="checkParams.telephoneStatusMsg"
            >
              <a-input
                :disabled="mdl.isUpdate <= 0"
                :maxLength="11"
                placeholder="请输入登记人手机号"
                v-decorator="['telephone', {initialValue: mdl.telephone, rules: [{required: true, validator: verifyTelephone, validateTrigger: 'change'}]}]"
              />
            </a-form-item>
            <a-form-item
              style="margin-bottom: 0px;"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="计划返程时间"
            >
              <a-row :gutter="36">
                <a-col :md="8" :sm="16">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="{xs: { span: 24 }, sm: { span: 19 }}"
                    :validateStatus="checkParams.planDateStatus"
                    :help="checkParams.planDateStatusMsg"
                  >
                    <a-date-picker
                      :disabled="mdl.isUpdate <= 0"
                      v-model="mdl.planDate"
                      style="width: 180px;"
                      valueFormat="YYYY-MM-DD"
                      @change="changePlanDate"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="28" :sm="16">
                  <a-form-item
                    :labelCol="{xs: { span: 30 }, sm: { span: 5 }}"
                    :wrapperCol="{xs: { span: 30 }, sm: { span: 30 }}"
                    :validateStatus="checkParams.planTimeStatus"
                    :help="checkParams.planTimeStatusMsg"
                  >
                    <a-time-picker
                      :disabled="mdl.isUpdate <= 0"
                      format="HH:mm"
                      valueFormat="HH:mm"
                      @change="changeStartTime"
                      style="width: 120px;"
                      :defaultValue="mdl.planStartTime"
                    />
                    ~
                    <a-time-picker
                      :disabled="mdl.isUpdate <= 0"
                      format="HH:mm"
                      valueFormat="HH:mm"
                      @change="changeEndTime"
                      style="width: 120px;"
                      :defaultValue="mdl.planEndTime"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="返回地址"
              style="margin-bottom: 0px;"
            >
              <a-row :gutter="36">
                <a-col :md="8" :sm="16">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :validateStatus="checkParams.countyToStatus"
                    :help="checkParams.countyToStatusMsg"
                  >
                    <a-cascader
                      :disabled="mdl.isUpdate <= 0"
                      :allowClear="false"
                      style="width:180px;"
                      :options="regionList"
                      placeholder="省-市-区(县)"
                      :field-names="{label: 'label', value: 'value', children: 'children' }"
                      @change="changeToRegion"
                      v-decorator="['countyTo', {initialValue: toDefaultRegion, rules: [{required: true}]}]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="26" :sm="16">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :validateStatus="checkParams.placeToStatus"
                    :help="checkParams.placeToStatusMsg"
                  >
                    <a-input
                      :disabled="mdl.isUpdate <= 0"
                      placeholder="请输入具体地址"
                      style="width:322px;"
                      v-decorator="['placeTo', {initialValue: mdl.placeTo, rules: [{required: true, validator: verifyPlaceTo, validateTrigger: 'change'}]}]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="交通工具"
            >
              <a-input
                :disabled="mdl.isUpdate <= 0"
                v-model="mdl.trafficWay"
                placeholder="交通工具"
              />
            </a-form-item>
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="家庭成员人数"
              style="margin-bottom: 0px;"
            >
              <a-row :gutter="28">
                <a-col :md="9" :sm="8">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                  >
                    <a-input-number
                      :disabled="mdl.isUpdate <= 0"
                      :min="1"
                      :max="100"
                      v-model="mdl.familyMember"
                      style="width: 140px;"
                      placeholder="家庭成员人数"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="15" :sm="8">
                  <a-form-item
                    :labelCol="{ xs: { span: 24 }, sm: { span: 8 }}"
                    :wrapperCol="wrapperCol"
                    label="疫苗接种次数"
                  >
                    <a-input-number
                      :disabled="mdl.isUpdate <= 0"
                      :min="0"
                      :max="100"
                      v-model="mdl.vaccination"
                      style="width: 120px;"
                      placeholder="疫苗接种次数"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="核酸检测已过小时数"
              style="margin-bottom: 0px;"
              v-if="mdl.isArrive === 1"
            >
              <a-row :gutter="28">
                <a-col :md="9" :sm="8">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                  >
                    <a-input-number
                      :disabled="mdl.isUpdate <= 0"
                      :min="1"
                      :max="100"
                      v-model="mdl.lastNatHours"
                      style="width: 140px;"
                      placeholder="核酸检测已过小时数"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="15" :sm="8">
                  <a-form-item
                    :labelCol="{ xs: { span: 24 }, sm: { span: 8 }}"
                    :wrapperCol="wrapperCol"
                    label="体温"
                  >
                    <a-input-number
                      :disabled="mdl.isUpdate <= 0"
                      :step="0.01"
                      :min="0"
                      :max="42"
                      v-model="mdl.bodyTemp"
                      style="width: 120px;"
                      placeholder="体温"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              label="实际返回时间"
              style="margin-bottom: 0px;"
              v-if="mdl.isArrive === 1"
            >
              <a-row :gutter="28">
                <a-col :md="9" :sm="8">
                  <a-form-item
                    :labelCol="labelCol"
                    :wrapperCol="wrapperCol"
                    :validateStatus="checkParams.backTimeActualStatus"
                    :help="checkParams.backTimeActualStatusMsg"
                  >
                    <a-date-picker
                      :disabled="mdl.isUpdate <= 0"
                      v-model="mdl.backTimeActual"
                      style="width: 140px;"
                      showTime
                      valueFormat="YYYY-MM-DD HH:mm:ss"
                      format="YYYY-MM-DD HH:mm:ss"
                      @change="changeBackTimeActual"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="15" :sm="8">
                  <a-form-item
                    :labelCol="{ xs: { span: 24 }, sm: { span: 8 }}"
                    :wrapperCol="wrapperCol"
                    label="实际交通工具"
                  >
                    <a-input
                      :disabled="mdl.isUpdate <= 0"
                      v-model="mdl.trafficWayActual"
                      placeholder="实际交通工具"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <deal-appoint @ok="handleOk" ref="dealappoint"></deal-appoint>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { regionList } from '@/api/region'
import { appointList, checkRecordInfo, saveBackAppointment, backCondition } from '@/api/backhome'
import DealAppoint from '@/views/back/components/DealAppoint'
const columns = [
  {
    title: '记录ID',
    dataIndex: 'keyId'
  },
  {
    title: '登记人姓名',
    dataIndex: 'visitorName'
  },
  {
    title: '登记人手机号',
    dataIndex: 'telephone'
  },
  {
    title: '出发地',
    dataIndex: 'fromAddress'
  },
  {
    title: '计划返回时间',
    dataIndex: 'planDateTime'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '添加时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '180px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'AppointList',
  components: {
    STable,
    Ellipsis,
    DealAppoint
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      loading: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        visitorName: '',
        telephone: '',
        backDate: '',
        provinceId: 0,
        cityId: 0,
        areaId: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return appointList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      checkParams: {
        visitorNameStatus: 'success',
        visitorNameStatusMsg: null,
        telephoneStatus: 'success',
        telephoneStatusMsg: null,
        countyFromStatus: 'success',
        countyFromStatusMsg: null,
        placeFromStatus: 'success',
        placeFromStatusMsg: null,
        placeToStatus: 'success',
        placeToStatusMsg: null,
        countyToStatus: 'success',
        countyToStatusMsg: null,
        planDateStatus: 'success',
        planDateStatusMsg: null,
        planTimeStatus: 'success',
        planTimeStatusMsg: null,
        backTimeActualStatus: 'success',
        backTimeActualStatusMsg: null,
        visitorTypeStatus: 'success',
        visitorTypeStatusMsg: null
      },
      dataParams: {
        communityName: ''
      },
      regionList: [],
      defaultRegion: [],
      fromDefaultRegion: [],
      toDefaultRegion: [],
      typeList: []
    }
  },
  filters: {
  },
  created () {
    this.backConditionApi()
    this.regionListApi()
  },
  methods: {
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    verifyVisitorName (rule, value, callback) {
      const _this = this
      _this.mdl.visitorName = value.trim()
      if (_this.mdl.visitorName !== '') {
        _this.checkParams.visitorNameStatus = 'success'
        _this.checkParams.visitorNameStatusMsg = null
      } else {
        _this.checkParams.visitorNameStatus = 'error'
        _this.checkParams.visitorNameStatusMsg = '请输入登记人姓名'
        callback(new Error('请输入登记人姓名'))
      }
    },
    verifyTelephone (rule, value, callback) {
      const _this = this
      _this.mdl.telephone = value.trim()
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(_this.mdl.telephone)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        callback(new Error('请输入正确的手机号码'))
      }
    },
    verifyPlaceFrom (rule, value, callback) {
      const _this = this
      _this.mdl.placeFrom = value.trim()
      if (_this.mdl.placeFrom !== '') {
        _this.checkParams.placeFromStatus = 'success'
        _this.checkParams.placeFromStatusMsg = null
      } else {
        _this.checkParams.placeFromStatus = 'error'
        _this.checkParams.placeFromStatusMsg = '请输入出发地'
        callback(new Error('请输入出发地'))
      }
    },
    verifyPlaceTo (rule, value, callback) {
      const _this = this
      _this.mdl.placeTo = value.trim()
      if (_this.mdl.placeTo !== '') {
        _this.checkParams.placeToStatus = 'success'
        _this.checkParams.placeToStatusMsg = null
      } else {
        _this.checkParams.placeToStatus = 'error'
        _this.checkParams.placeToStatusMsg = '请输入目的地'
        callback(new Error('请输入目的地'))
      }
    },
    changeFromRegion (e) {
      this.fromDefaultRegion = e
      this.mdl.provinceFrom = e[0] ? e[0] : 0
      this.mdl.cityFrom = e[1] ? e[1] : 0
      this.mdl.countyFrom = e[2] ? e[2] : 0

      if (this.mdl.countyFrom === '' || this.mdl.countyFrom === 0) {
        this.checkParams.countyFromStatus = 'error'
        this.checkParams.countyFromStatusMsg = '请选择区域'
      } else {
        this.checkParams.countyFromStatus = 'success'
        this.checkParams.countyFromStatusMsg = null
      }
    },
    changeToRegion (e) {
      this.toDefaultRegion = e
      this.mdl.provinceTo = e[0] ? e[0] : 0
      this.mdl.cityTo = e[1] ? e[1] : 0
      this.mdl.countyTo = e[2] ? e[2] : 0

      if (this.mdl.countyTo === '' || this.mdl.countyTo === 0) {
        this.checkParams.countyToStatus = 'error'
        this.checkParams.countyToStatusMsg = '请选择区域'
      } else {
        this.checkParams.countyToStatus = 'success'
        this.checkParams.countyToStatusMsg = null
      }
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.queryParam = {
        visitorName: '',
        telephone: '',
        backDate: '',
        provinceId: 0,
        cityId: 0,
        areaId: 0
      }
    },
    changeStartTime (time) {
      console.log('changeStartTime', time)
      if (time !== null) {
        const timeSting = time
        this.mdl.planStartTime = timeSting.trim()
        if (this.mdl.planEndTime.trim() !== '') {
          if (this.mdl.planStartTime.trim() > this.mdl.planEndTime.trim()) {
            this.checkParams.planTimeStatus = 'error'
            this.checkParams.planTimeStatusMsg = '开始时间点不能大与结束时间点'
          } else {
            this.checkParams.planTimeStatus = 'success'
            this.checkParams.planTimeStatusMsg = null
          }
        } else {
          this.checkParams.planTimeStatus = 'success'
          this.checkParams.planTimeStatusMsg = null
        }
        console.log('changeStartTime', timeSting)
      } else {
        this.mdl.planStartTime = ''
        this.checkParams.planTimeStatus = 'error'
        this.checkParams.planTimeStatusMsg = '请选择返回时间点'
      }
    },
    changeEndTime (time) {
      console.log('changeEndTime', time)
      if (time !== null) {
        const timeSting = time
        this.mdl.planEndTime = timeSting.trim()
        if (this.mdl.planStartTime.trim() !== '') {
          if (this.mdl.planStartTime.trim() > this.mdl.planEndTime.trim()) {
            this.checkParams.planTimeStatus = 'error'
            this.checkParams.planTimeStatusMsg = '开始时间点不能大与结束时间点'
          } else {
            this.checkParams.planTimeStatus = 'success'
            this.checkParams.planTimeStatusMsg = null
          }
        } else {
          this.checkParams.planTimeStatus = 'success'
          this.checkParams.planTimeStatusMsg = null
        }
        console.log('changeEndTime', timeSting)
      } else {
        this.mdl.planEndTime = ''
        this.checkParams.planTimeStatus = 'error'
        this.checkParams.planTimeStatusMsg = '请选择返回时间点'
      }
    },
    changePlanDate (e) {
      if (e === '' || e === null) {
        this.checkParams.planDateStatus = 'error'
        this.checkParams.planDateStatusMsg = '请选择返回日期'
      } else {
        this.checkParams.planDateStatus = 'success'
        this.checkParams.planDateStatusMsg = null
      }
      console.log('changePlanDate', e)
    },
    changeBackTimeActual (e) {
      if (e === '' || e === null) {
        this.checkParams.backTimeActualStatus = 'error'
        this.checkParams.backTimeActualStatusMsg = '请选择实际返回日期'
      } else {
        this.checkParams.backTimeActualStatus = 'success'
        this.checkParams.backTimeActualStatusMsg = null
      }
      console.log('changePlanDate', e)
    },
    saveBackInfoApi () {
      const _this = this
      if (_this.mdl.isUpdate <= 0) {
        _this.visible = false
        return false
      }
      console.log('saveBackInfoApi', _this.mdl)
      if (_this.mdl.countyFrom === '' || _this.mdl.countyFrom === 0) {
        _this.checkParams.countyFromStatus = 'error'
        _this.checkParams.countyFromStatusMsg = '请选择区域'
        return false
      } else {
        _this.checkParams.countyFromStatus = 'success'
        _this.checkParams.countyFromStatusMsg = null
      }

      if (_this.mdl.placeFrom !== '') {
        _this.checkParams.placeFromStatus = 'success'
        _this.checkParams.placeFromStatusMsg = null
      } else {
        _this.checkParams.placeFromStatus = 'error'
        _this.checkParams.placeFromStatusMsg = '请输入出发地'
        return false
      }

      if (_this.mdl.visitorName !== '') {
        _this.checkParams.visitorNameStatus = 'success'
        _this.checkParams.visitorNameStatusMsg = null
      } else {
        _this.checkParams.visitorNameStatus = 'error'
        _this.checkParams.visitorNameStatusMsg = '请输入登记人姓名'
        return false
      }

      if (_this.mdl.visitorType !== '' && _this.mdl.visitorType !== 0) {
        _this.checkParams.visitorTypeStatus = 'success'
        _this.checkParams.visitorTypeStatusMsg = null
      } else {
        _this.checkParams.visitorTypeStatus = 'error'
        _this.checkParams.visitorTypeStatusMsg = '请选择类型'
        return false
      }

      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(_this.mdl.telephone)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        return false
      }

      if (_this.mdl.planDate === '' || _this.mdl.planDate === null) {
        this.checkParams.planDateStatus = 'error'
        this.checkParams.planDateStatusMsg = '请选择返回日期'
        return false
      } else {
        this.checkParams.planDateStatus = 'success'
        this.checkParams.planDateStatusMsg = null
      }

      if (this.mdl.planEndTime.trim() === '' || this.mdl.planStartTime.trim() === '') {
        this.checkParams.planTimeStatus = 'error'
        this.checkParams.planTimeStatusMsg = '请选择返回时间点'
        return false
      } else {
        if (this.mdl.planStartTime.trim() > this.mdl.planEndTime.trim()) {
          this.checkParams.planTimeStatus = 'error'
          this.checkParams.planTimeStatusMsg = '开始时间点不能大与结束时间点'
          return false
        } else {
          this.checkParams.planTimeStatus = 'success'
          this.checkParams.planTimeStatusMsg = null
        }
      }

      if (this.mdl.countyTo === '' || this.mdl.countyTo === 0) {
        this.checkParams.countyToStatus = 'error'
        this.checkParams.countyToStatusMsg = '请选择区域'
        return false
      } else {
        this.checkParams.countyToStatus = 'success'
        this.checkParams.countyToStatusMsg = null
      }

      if (_this.mdl.placeTo !== '') {
        _this.checkParams.placeToStatus = 'success'
        _this.checkParams.placeToStatusMsg = null
      } else {
        _this.checkParams.placeToStatus = 'error'
        _this.checkParams.placeToStatusMsg = '请输入目的地'
        return false
      }

      if (_this.mdl.isArrive === 1) {
        if (_this.mdl.backTimeActual === '' || _this.mdl.backTimeActual === null || _this.mdl.backTimeActual === undefined) {
          this.checkParams.backTimeActualStatus = 'error'
          this.checkParams.backTimeActualStatusMsg = '请选择实际返回日期'
          return false
        } else {
          this.checkParams.backTimeActualStatus = 'success'
          this.checkParams.backTimeActualStatusMsg = null
        }
      }

      _this.loading = true
      saveBackAppointment(_this.mdl).then((res) => {
        if (res.errorCode === 0) {
          if (_this.mdl.keyId > 0) {
            _this.$message.success('更新成功')
          } else {
            _this.$message.success('添加成功')
          }
          _this.visible = false
          _this.loading = false
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleAdd () {
      this.mdl = {
        keyId: 0,
        id: '',
        visitorName: '',
        visitorType: 0,
        telephone: '',
        provinceFrom: '',
        cityFrom: '',
        countyFrom: '',
        placeFrom: '',
        provinceTo: '',
        cityTo: '',
        countyTo: '',
        placeTo: '',
        planDate: '',
        planStartTime: '',
        planEndTime: '',
        trafficWay: '',
        familyMember: 1,
        vaccination: 0,
        isUpdate: 1,
        isArrive: 0,
        lastNatHours: 0,
        bodyTemp: '',
        trafficWayActual: '',
        backTimeActual: ''
      }
      this.checkParams.visitorNameStatus = 'success'
      this.checkParams.visitorNameStatusMsg = null
      this.checkParams.telephoneStatus = 'success'
      this.checkParams.telephoneStatusMsg = null
      this.checkParams.countyFromStatus = 'success'
      this.checkParams.countyFromStatusMsg = null
      this.checkParams.placeFromStatus = 'success'
      this.checkParams.placeFromStatusMsg = null
      this.checkParams.placeToStatus = 'success'
      this.checkParams.placeToStatusMsg = null
      this.checkParams.countyToStatus = 'success'
      this.checkParams.countyToStatusMsg = null
      this.checkParams.planDateStatus = 'success'
      this.checkParams.planDateStatusMsg = null
      this.checkParams.planTimeStatus = 'success'
      this.checkParams.planTimeStatusMsg = null
      this.checkParams.backTimeActualStatus = 'success'
      this.checkParams.backTimeActualStatusMsg = null
      this.toDefaultRegion = []
      this.fromDefaultRegion = []
      this.visible = true
    },
    handleEdit (record, isArrive) {
      this.loading = true
      const _this = this
      this.checkParams.visitorNameStatus = 'success'
      this.checkParams.visitorNameStatusMsg = null
      this.checkParams.telephoneStatus = 'success'
      this.checkParams.telephoneStatusMsg = null
      this.checkParams.countyFromStatus = 'success'
      this.checkParams.countyFromStatusMsg = null
      this.checkParams.placeFromStatus = 'success'
      this.checkParams.placeFromStatusMsg = null
      this.checkParams.placeToStatus = 'success'
      this.checkParams.placeToStatusMsg = null
      this.checkParams.countyToStatus = 'success'
      this.checkParams.countyToStatusMsg = null
      this.checkParams.planDateStatus = 'success'
      this.checkParams.planDateStatusMsg = null
      this.checkParams.planTimeStatus = 'success'
      this.checkParams.planTimeStatusMsg = null
      this.checkParams.backTimeActualStatus = 'success'
      this.checkParams.backTimeActualStatusMsg = null
      if (isArrive === 1) {
        checkRecordInfo({ appointmentId: record.id }).then((res) => {
          if (res.errorCode === 0) {
            if (res.result.keyId > 0) {
              _this.mdl = Object.assign({}, res.result)
              _this.mdl.keyId = record.keyId
              _this.mdl.id = record.id
              _this.mdl.isArrive = isArrive
              _this.fromDefaultRegion = [res.result.provinceFrom, res.result.cityFrom, res.result.countyFrom]
              _this.toDefaultRegion = [res.result.provinceTo, res.result.cityTo, res.result.countyTo]
            } else {
              _this.mdl = Object.assign({}, record)
              _this.mdl.isArrive = isArrive
              _this.fromDefaultRegion = [record.provinceFrom, record.cityFrom, record.countyFrom]
              _this.toDefaultRegion = [record.provinceTo, record.cityTo, record.countyTo]
            }
            _this.visible = true
            _this.loading = false
          } else {
            _this.$message.error(res.errorMsg)
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        _this.mdl = Object.assign({}, record)
        _this.mdl.isArrive = isArrive
        _this.fromDefaultRegion = [record.provinceFrom, record.cityFrom, record.countyFrom]
        _this.toDefaultRegion = [record.provinceTo, record.cityTo, record.countyTo]
        _this.visible = true
        _this.loading = false
      }
      console.log(_this.mdl)
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.provinceId = e[0] ? e[0] : 0
      this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaId = e[2] ? e[2] : 0
    },
    gotoDealInfo (data) {
      this.$refs.dealappoint.ininData(data)
    },
    backConditionApi () {
      const _this = this
      backCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.typeList = res.result.typeList
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeType (e) {
      this.mdl.visitorType = e
      if (this.mdl.visitorType !== '' && this.mdl.visitorType !== 0) {
        this.checkParams.visitorTypeStatus = 'success'
        this.checkParams.visitorTypeStatusMsg = null
      } else {
        this.checkParams.visitorTypeStatus = 'error'
        this.checkParams.visitorTypeStatusMsg = '请选择类型'
      }
    }
  },
  watch: {
  }
}
</script>
