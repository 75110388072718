import request from '@/utils/request'
const baseURL = '/baseapi'
const BackhomeApi = {
  AppointList: '/admin/backhome/appointList',
  RecordList: '/admin/backhome/recordList',
  SaveBackAppointment: '/admin/backhome/saveBackAppointment',
  CheckRecordInfo: '/admin/backhome/checkRecordInfo',
  SaveBackRecord: '/admin/backhome/saveBackRecord',
  VerifyStatus: '/admin/backhome/verifyStatus',
  Condition: '/admin/backhome/condition'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function appointList (parameter) {
  return request({
    baseURL: baseURL,
    url: BackhomeApi.AppointList,
    method: 'post',
    data: parameter
  })
}

export function recordList (parameter) {
  return request({
    baseURL: baseURL,
    url: BackhomeApi.RecordList,
    method: 'post',
    data: parameter
  })
}

export function saveBackAppointment (parameter) {
  return request({
    baseURL: baseURL,
    url: BackhomeApi.SaveBackAppointment,
    method: 'post',
    data: parameter
  })
}

export function checkRecordInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: BackhomeApi.CheckRecordInfo,
    method: 'post',
    data: parameter
  })
}

export function saveBackRecord (parameter) {
  return request({
    baseURL: baseURL,
    url: BackhomeApi.SaveBackRecord,
    method: 'post',
    data: parameter
  })
}

export function verifyStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: BackhomeApi.VerifyStatus,
    method: 'post',
    data: parameter
  })
}

export function backCondition () {
  return request({
    baseURL: baseURL,
    url: BackhomeApi.Condition,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
